<template>
  <div>
    <div id="small_img_wrap">
      <img id="small_img" :src="'/img/'+series_name+'/'+artwork.big_img">
    </div>

    <div id="text_wrap">
      <div id="text">
        <p id="title">{{artwork.title[lang]}}</p>
        <p id=" specification">{{artwork.year}}, {{artwork.media}}, {{artwork.size}}</p>
      </div>
    </div>


    <img id="big_img" :src="'/img/'+series_name+'/'+artwork.big_img">
  </div>
</template>

<script>
import artwork from '@/assets/yml/artwork.yml'
export default {
  name: 'ShowImage',
  data () {
    return {
      series_name: this.$route.params.series_name,
      artwork: this.artwork,
      lang: window.lang,
    }
  },
  created(){
    const series_name = this.$route.params.series_name
    const artwork_id = this.$route.params.artwork_id

    if(artwork &&
       artwork[series_name] &&
       artwork[series_name][artwork_id] ){
      this.artwork = artwork[series_name][artwork_id]
    }
    else{
      location.href = '/'
    }


  },
  methods:{

  },
  watch:{
    '$route': function(){

    }
  },
}
</script>

<style>
img#small_img{
  margin: 150px auto 100px;
  max-height: 80vh;
  max-width: 80%;
  border: solid 1px #aaa;
  box-shadow: 0px 0px 55px #51535a;
}
#text{
  margin-bottom: 150px;
}
#title{
  font-size: 20px;
  margin-bottom: 12px;
}

img#big_img{
  margin: 150px auto;
  max-width: 95%;
  border: solid 1px #aaa;
}
@media print{
  h1, #humberger, footer{
    display: none;
  }
  img#big_img{
    display: none;
  }
  #small_img_wrap{
    height: 100vh;
    position: relative;
  }
  img#small_img{
    width: 70%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  #text_wrap{
    height: 100vh;
    position: relative;
    display: table;
  }
  #text{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #000;
    display: inherit;
    width: 100vw;
  }
}
</style>